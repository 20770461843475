import React from "react";
import CryptoBot from "./CryptoBot";
import "./App.css";

function App() {
  return (
    <div className="main-div">
      <div className="d-flex justify-content-center mt-4">
        <CryptoBot />
      </div>
    </div>
  );
}

export default App;
