import React, { Component } from "react";
import PropTypes from "prop-types";
import ChatBot, { Loading } from "react-simple-chatbot";
import axios from "axios";

const baseUrl = "https://api.coingecko.com/api/v3";

class ChatResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      result: "",
      trigger: false,
    };

    this.triggetNext = this.triggetNext.bind(this);
  }

  onClick = async (s) => {
    try {
      const symbol = s.toLowerCase();
      const { data } = await axios.get(`${baseUrl}/coins/${symbol}`);
      this.setState({
        loading: false,
        result: {
          status: true,
          price: data.market_data.current_price.usd,
          symbol: data.symbol,
          name: data.name,
          logo: data.image.small,
        },
      });
    } catch (error) {
      this.setState({
        loading: false,
        result: {
          status: false,
          message: `No Coin found with name ${this.props.steps.search.value}`,
        },
      });
    }
  };

  componentDidMount() {
    this.onClick(this.props.steps.search.value);
  }

  triggetNext() {
    this.setState({ trigger: true }, () => {
      this.props.triggerNextStep();
    });
  }

  render() {
    const { trigger, loading, result } = this.state;

    return (
      <div>
        {loading ? (
          <Loading />
        ) : (
          <Result
            result={result}
            trigger={trigger}
            triggetNext={this.triggetNext}
          />
        )}
      </div>
    );
  }
}

const Result = (props) => {
  return (
    <div>
      {props.result.status ? (
        <>
          <div>
            <img
              style={{ width: "50px" }}
              alt={props.result.name}
              src={props.result.logo}
            />
          </div>
          <p>
            <b>
              {props.result.name}({props.result.symbol})
            </b>
          </p>
          <p>Price ${props.result.price}</p>
        </>
      ) : (
        <p>{props.result.message}</p>
      )}
      <div
        style={{
          textAlign: "center",
          marginTop: 20,
        }}
      >
        {!props.trigger && (
          <button
            className="search-btn search-again"
            onClick={() => props.triggetNext()}
          >
            Search Again
          </button>
        )}
      </div>
    </div>
  );
};

ChatResult.propTypes = {
  steps: PropTypes.object,
  triggerNextStep: PropTypes.func,
};

ChatResult.defaultProps = {
  steps: undefined,
  triggerNextStep: undefined,
};

const CryptoBot = () => (
  <ChatBot
    steps={[
      {
        id: "1",
        message: "Hello Welcome to cryptobot",
        trigger: "2",
      },
      {
        id: "2",
        message: "Type crypto name (Bitcoin, Ethereum)",
        trigger: "search",
      },
      {
        id: "search",
        user: true,
        trigger: "3",
      },
      {
        id: "3",
        component: <ChatResult />,
        waitAction: true,
        trigger: "2",
      },
    ]}
  />
);

export default CryptoBot;
